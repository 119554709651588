'use strict';

function myCvzeker() {

}

myCvzeker.prototype.initialize = function() {

  if ( $('div').hasClass('cvz-get-relation') ) {
    this.getRelation();
  }

  if ( $('div').hasClass('cvz-edit-relation') ) {
    this.editRelation();
  }

  if ( $('div').hasClass('cvz-get-orders') ) {
    this.getOrders();
  }

  if ( $('div').hasClass('cvz-get-boilers') ) {
    this.getBoilers();
  }

  if ( $('div').hasClass('cvz-get-quotations') ) {
    this.getQuotations();
  }
};

myCvzeker.prototype.getRelation = function() {

  $.ajax({
    method: 'POST',
    url: cvzeker.ajax_url,
    data: {
      action: 'mkoz_mycvzeker_get_relation',
    },
    beforeSend: () => {
      $('.cvz-get-relation .cvz-my-cvzeker-message').html('Gegevens worden opgehaald<span>.</span><span>.</span><span>.</span>')
    }
  })
    .done(function (list) {
      console.log(list.contact)
      if ($(list.contact).length === 0) {
        $('.cvz-get-relation').append('Er zijn geen gegevens');
      } else {
        $(list).each(function () {
          let country = ''

          switch (this.address[0].country) {
            case 'NL':
              country = 'Nederland'
              break;
            default:
          }
          const htmlContact = '<strong>Contactinformatie</strong><br>' + this.contact[0].firstName + ' ' + this.contact[0].lastName + '<br>' + this.contact[0].emailAddress + '<br>' + this.contact[0].phoneNumber
          const htmlAddress = '<br><br><strong>Adresgegevens</strong><br>' + this.address[0].street + ' ' + this.address[0].number + ' ' + this.address[0].numberAddition + '<br>' + this.address[0].postcode + ' ' + this.address[0].city + '<br>' + country
          $('.cvz-get-relation').append(htmlContact);
          $('.cvz-get-relation').append(htmlAddress);
        })
      }
    })
    .fail(function (error) {
    })
    .always(() => {
      $('.cvz-get-relation .cvz-my-cvzeker-message').html('')
    })
};

myCvzeker.prototype.editRelation = function() {

  $.ajax({
    method: 'POST',
    url: cvzeker.ajax_url,
    data: {
      action: 'mkoz_mycvzeker_get_relation',
    },
    beforeSend: () => {
      $('.cvz-edit-relation .cvz-my-cvzeker-message').html('Gegevens worden opgehaald<span>.</span><span>.</span><span>.</span>')
    }
  })
    .done(function (list) {
      $(list).each(function() {
        var form = document.querySelector('form#cvz-update-relation')
        const genderElement = $('input:radio[name="gender_option"]')
        const firstNameElement = $('input[name="first_name"]')
        const lastNameElement = $('input[name="last_name"]')
        const emailAddressElement = $('input[name="emailadres"]')
        const phoneNumberElement = $('input[name="phonenumber"]')
        const postcodeElement = $('input[name="postcode"]')
        const numberElement = $('input[name="number"]')
        const numberAdditionElement = $('input[name="numberAddition"]')
        const streetElement = $('input[name="street"]')
        const cityElement = $('input[name="city"]')
        const addressElement = this.address[0]['@id']
        const contactElement = this.contact[0]['@id']

        $('input:radio[name="gender_option"][value="' + this.contact[0].gender + '"]').prop( 'checked', true );
        firstNameElement.val(this.contact[0].firstName)
        lastNameElement.val(this.contact[0].lastName)
        emailAddressElement.val(this.contact[0].emailAddress)
        phoneNumberElement.val(this.contact[0].phoneNumber)
        postcodeElement.val(this.address[0].postcode)
        numberElement.val(this.address[0].number)
        numberAdditionElement.val(this.address[0].numberAddition)
        streetElement.val(this.address[0].street)
        cityElement.val(this.address[0].city)

        $('.send-update-relation').click(function (ev) {
          ev.preventDefault()

          if($(form).find('.has-error').length === 0) {
            $.ajax({
              method: 'POST',
              url: cvzeker.ajax_url,
              data: {
                action: 'mkoz_mycvzeker_update_relation',
                addressURI: addressElement,
                contactURI: contactElement,
                gender: genderElement.val(),
                firstName: firstNameElement.val(),
                lastName: lastNameElement.val(),
                emailAddress: emailAddressElement.val(),
                phoneNumber: phoneNumberElement.val(),
                postcode: postcodeElement.val(),
                number: numberElement.val(),
                numberAddition: numberAdditionElement.val(),
                street: streetElement.val(),
                city: cityElement.val(),
              },
              beforeSend: () => {
                $('.error-message').html('')
              }
            })
              .done(() => {
                $('#cvz-update-relation').html('Gegevens zijn aangepast')
              })
              .fail(() => {
              })
              .always(() => {
              })
          }
        })

      })
    })
    .fail(function (error) {
    })
    .always(() => {
      $('.cvz-edit-relation .cvz-my-cvzeker-message').html('')
    })
};

myCvzeker.prototype.getOrders = function() {

  $.ajax({
    method: 'POST',
    url: cvzeker.ajax_url,
    data: {
      action: 'mkoz_mycvzeker_get_orders',
    },
    beforeSend: () => {
      $('.cvz-get-orders .cvz-my-cvzeker-message').html('Bestellingen worden opgehaald<span>.</span><span>.</span><span>.</span>')
    }
  })
    .done(function (list) {
      function custom_sort(a, b) {
        let n =  Object.values(a);
        let p =  Object.values(b);
        return new Date(p[0].date).getTime() - new Date(n[0].date).getTime();
      }
      list.sort(custom_sort);


      if(list === null) {
        $('.cvz-get-orders').append('Er zijn geen bestellingen');
      } else {
        $(list).each(function() {
          var htmlItemLinesShort = ''
          var htmlItemLines = ''
          $(this.order_lines).each(function() {
            htmlItemLinesShort += this.description + ' - '
            htmlItemLines += this.description + ' - €' + parseFloat(this.price ).toFixed(2) + ',- <br>'
          })

          let d = new Date(this.order.date)
          const htmlItem = '<div class="collapse-wrapper fl-accordion-item"> <div class="entry-title upgrade collapse-head fl-accordion-button"> <div class="fl-accordion-button-label cvz-label-'+ this.order.status +'">Bestelling: '+ this.order.number +' - <span class="referentie">Referentie: ' + htmlItemLinesShort + '</span> Datum: '+ d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear() +'</div> <div class="fl-accordion-button-toggle"> <i class="fl-accordion-button-icon fa fa-angle-double-down"></i> </div> </div> <div class="fl-accordion-content fl-clearfix">'+ htmlItemLines +'</div> </div>'
          $('.cvz-get-orders').append(htmlItem);
        })
      }
    })
    .fail(function (error) {
    })
    .always(() => {
      $('.cvz-get-orders .cvz-my-cvzeker-message').html('')
    })
};

myCvzeker.prototype.getBoilers = function() {
  const self = this

  $.ajax({
    method: 'POST',
    url: cvzeker.ajax_url,
    data: {
      action: 'mkoz_mycvzeker_get_orders',
    },
    beforeSend: () => {
      $('.cvz-get-boilers .cvz-my-cvzeker-message').html('Ketels worden opgehaald<span>.</span><span>.</span><span>.</span>')
      $('.cvz-get-new-orders .cvz-my-cvzeker-message').html('Bestellingen worden opgehaald<span>.</span><span>.</span><span>.</span>')
      $('.cvz-get-new-maintenance .cvz-my-cvzeker-message').html('Abonnementen worden opgehaald<span>.</span><span>.</span><span>.</span>')
    }
  })
    .done(function (list) {
      function custom_sort(a, b) {
        let n =  Object.values(a);
        let p =  Object.values(b);
        return new Date(p[0].date).getTime() - new Date(n[0].date).getTime();
      }

      if(list === null) {
        $('.cvz-get-boilers').append('Er zijn geen bestellingen');
        $('.cvz-get-new-orders').append('Er zijn geen openstaande bestellingen');
        $('.cvz-get-new-maintenance').append('Er zijn geen abonnementen');
      } else {
        list.sort(custom_sort);

        if ( $('div').hasClass('cvz-get-new-orders') ) {
          self.getNewOrders(list)
        }

        if ( $('div').hasClass('cvz-get-new-maintenance') ) {
          self.getNewMaintenance(list)
        }

        $(list).each(function() {
          var htmlBoilerItem = ''
          var htmlItemLinesShort = ''
          var htmlItemLines = ''

          htmlBoilerItem = this.order_lines[0].description

          $(this.order_lines).each(function() {
            htmlItemLinesShort += this.description + ' - '
            htmlItemLines += this.description + ' - €' + parseFloat(this.price ).toFixed(2) + ',- <br>'
          })

          let d = new Date(this.order.date)
          const htmlItem = '<div class="collapse-wrapper fl-accordion-item"> <div class="entry-title upgrade collapse-head fl-accordion-button"> <div class="fl-accordion-button-label cvz-label-'+ this.order.status +'">' + htmlBoilerItem + '</div> <div class="fl-accordion-button-toggle"> <i class="fl-accordion-button-icon fa fa-angle-double-down"></i> </div> </div> <div class="fl-accordion-content fl-clearfix"> Datum: '+ d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear() + '<br>' +  htmlItemLines +'</div> </div>'
          $('.cvz-get-boilers').append(htmlItem);
        })
      }
    })
    .fail(function (error) {
    })
    .always(() => {
      $('.cvz-get-boilers .cvz-my-cvzeker-message').html('')
      $('.cvz-get-new-orders .cvz-my-cvzeker-message').html('')
      $('.cvz-get-new-maintenance .cvz-my-cvzeker-message').html('')
    })
};

myCvzeker.prototype.getNewOrders = function(list) {
  $(list).each(function() {
    if (this.order.status === 'new') {
      var htmlBoilerItem = ''
      var htmlItemLinesShort = ''
      var htmlItemLines = ''

      htmlBoilerItem = this.order_lines[0].description

      $(this.order_lines).each(function () {
        htmlItemLinesShort += this.description + ' - '
        htmlItemLines += this.description + ' - €' + parseFloat(this.price).toFixed(2) + ',- <br>'
      })

      let d = new Date(this.order.date)
      const htmlItem = '<div class="collapse-wrapper fl-accordion-item"> <div class="entry-title upgrade collapse-head fl-accordion-button"> <div class="fl-accordion-button-label cvz-label-' + this.order.status + '">' + htmlBoilerItem + '</div> <div class="fl-accordion-button-toggle"> <i class="fl-accordion-button-icon fa fa-angle-double-down"></i> </div> </div> <div class="fl-accordion-content fl-clearfix"> Datum: ' + d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear() + '<br>' + htmlItemLines + '</div> </div>'
      $('.cvz-get-new-orders').append(htmlItem);
    }
  })

};

myCvzeker.prototype.getNewMaintenance = function(list) {
  $(list).each(function() {

      var htmlMaintenanceItem = ''
      var htmlItemLinesShort = ''
      var htmlItemLines = ''

      $(this.order_lines).each(function () {
        htmlItemLinesShort += this.description + ' - '
        htmlItemLines += this.description + ' - €' + parseFloat(this.price).toFixed(2) + ',- <br>'

        if(this.description.toLowerCase().indexOf("verzekerd") >= 0 || this.description.toLowerCase().indexOf("onderhoud") >= 0) {
          htmlMaintenanceItem = this.description
        }
      })

      if(htmlItemLinesShort.toLowerCase().indexOf("verzekerd") >= 0 || htmlItemLinesShort.toLowerCase().indexOf("onderhoud") >= 0) {

      let d = new Date(this.order.date)
      const htmlItem = '<div class="collapse-wrapper fl-accordion-item"> <div class="entry-title upgrade collapse-head fl-accordion-button"> <div class="fl-accordion-button-label cvz-label-' + this.order.status + '">' + htmlMaintenanceItem + '</div> <div class="fl-accordion-button-toggle"> <i class="fl-accordion-button-icon fa fa-angle-double-down"></i> </div> </div> <div class="fl-accordion-content fl-clearfix"> Datum: ' + d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear() + '<br>' + htmlItemLines + '</div> </div>'
      $('.cvz-get-new-maintenance').append(htmlItem);
      }
  })

};

myCvzeker.prototype.getQuotations = function() {
  $.ajax({
    method: 'POST',
    url: cvzeker.ajax_url,
    data: {
      action: 'mkoz_mycvzeker_get_quotations',
    },
    beforeSend: () => {
      $('.cvz-get-quotations .cvz-my-cvzeker-message').html('Offertes worden opgehaald<span>.</span><span>.</span><span>.</span>')
    }
  })
    .done(function (list) {
      function custom_sort(a, b) {
        let n =  Object.values(a);
        let p =  Object.values(b);
        return new Date(p[0].date).getTime() - new Date(n[0].date).getTime();
      }
      list.sort(custom_sort);

      if(list === null) {
        $('.cvz-get-quotations').append('Er zijn geen offertes');
      } else {
        $(list).each(function () {
          var htmlItemLinesShort = ''
          var htmlItemLines = ''
          $(this.quotation_lines).each(function () {
            htmlItemLinesShort += this.description + ' - '
            htmlItemLines += this.description + ' - €' + parseFloat(this.price).toFixed(2) + ',- <br>'
          })

          let d = new Date(this.quotation.date)
          const htmlItem = '<div class="collapse-wrapper fl-accordion-item"> <div class="entry-title upgrade collapse-head fl-accordion-button"> <div class="fl-accordion-button-label cvz-label-'+ this.quotation.status +'">Offerte: ' + this.quotation.number + ' - <span class="referentie">Referentie: ' + htmlItemLinesShort + '</span> Datum: ' + d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear() + '</div> <div class="fl-accordion-button-toggle"> <i class="fl-accordion-button-icon fa fa-angle-double-down"></i> </div> </div> <div class="fl-accordion-content fl-clearfix">' + htmlItemLines + '</div> </div>'
          $('.cvz-get-quotations').append(htmlItem);
        })
      }
    })
    .fail(function (error) {
      console.log(error)
    })
    .always(() => {
      $('.cvz-get-quotations .cvz-my-cvzeker-message').html('')
    })
};

module.exports = new myCvzeker();
'use strict';

let menuMouseObserver = null;

function Menu() {
    this.currentMenuItem = null;
    this.lastMenuItem = null;
    this.mainNavigation = null;
    this.navToggle = null;
}

Menu.prototype.initialize = function () {
    this.mainNavigation = $('#nav_main');
    this.navToggle = $('#nav_toggle');

    this.bindEvents();
};

Menu.prototype.bindEvents = function () {
    const self = this;

    self.mainNavigation.on('click mouseenter mouseleave', '> ul > li > a', function (e) {
        self.currentMenuItem = $(this).parent('li');
        self.lastMenuItem = self.mainNavigation.find('> ul > li').not($(this).parent('li'));


        if (self.currentMenuItem.hasClass('menu-item-has-children') && e.type === 'click') {
            //e.preventDefault();
        }

        if (!self.mobileMenuActive()) {
            if (e.type === 'mouseenter') {
                self.mainNavigation.find('> ul > li').not(self.currentMenuItem).removeClass('open');

                self.disableTimeout();

                self.currentMenuItem.toggleClass('open', true);
            } else if (e.type === 'mouseleave') {
                self.enableTimeout();
            }
        } else {
            if (e.type === 'click' && !($.inArray("menu-item-has-children", e.target.parentElement.classList) === -1)) {
                self.lastMenuItem.toggleClass('open', false);
                self.currentMenuItem.toggleClass('open');
                e.preventDefault();
            }

        }
    });

    self.mainNavigation.on('mouseenter mouseleave', '> ul > li > ul', function (e) {
        self.currentMenuItem = $(this).parent('li');

        if (!self.mobileMenuActive()) {
            if (e.type === 'mouseenter') {
                self.disableTimeout();
            } else {
                self.enableTimeout();
            }
        }
    });

    self.navToggle.on('click', function () {
        const body = $('body');
        const header = $('#header');

        body.toggleClass('nav-toggle-active');
    });
};

Menu.prototype.enableTimeout = function () {
    const self = this;

    menuMouseObserver = setTimeout(function () {
        self.currentMenuItem.toggleClass('open', false);
    }, 500);
};

Menu.prototype.disableTimeout = function () {
    clearTimeout(menuMouseObserver);
};

Menu.prototype.mobileMenuActive = function () {
    return $('body').hasClass('nav-toggle-active');
};

module.exports = new Menu();

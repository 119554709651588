'use strict';

if($('.gform_wrapper').length) {
  var d = new Date()
  var currentDay = (d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear())

  gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
    optionsObj.minDate = 0
    optionsObj.firstDay = 1
    optionsObj.beforeShowDay = function (date) {
      var day = date.getDay()
      var gfCurrent = (date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear())
      var show = true
      if (day == 6 || day == 0) show = false
      if (currentDay === gfCurrent) show = false
      return [show]
    }

    return optionsObj
  })
}




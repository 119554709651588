export default class AddressLookup {
  constructor (props) {
    this.container = props.container
    this.addressAutocompleteRunning = false
    this.addressAutocompleteTimeout = null
    this.autocompleteDelay = 1000

    this.postcodeElement = $('input[data-element="postcode"]', this.container)
    this.numberElement = $('input[data-element="number"]', this.container)
    this.numberAdditionElement = $('input[data-element="numberAddition"]', this.container)
    this.streetElement = $('input[data-element="street"]', this.container)
    this.cityElement = $('input[data-element="city"]', this.container)

    this.addressContainer = $('.mcw_form_adres_house');

    this.bindEvents()
  }

  bindEvents () {
    this.postcodeElement.on('change paste keyup', this.handlePostcode.bind(this))
    this.numberElement.on('change paste keyup', this.handleNumber.bind(this))
    this.numberAdditionElement.on('change paste keyup', this.handleNumberAddition.bind(this))
  }

  handlePostcode (e) {

    const item = $(e.currentTarget)
    const value = $(item).val()

    switch (e.type) {
      case 'keyup':
        $(item).val(value.replace(/\s/g, '').toUpperCase())

        clearTimeout(this.addressAutocompleteTimeout)

        break
    }

    this.addressAutocompleteTimeout = setTimeout(() => {
      this.addressAutocomplete()
    }, this.autocompleteDelay)
  }

  handleNumber (e) {

    const item = $(e.currentTarget)
    const value = $(item).val()

    switch (e.type) {
      case 'keyup':
        $(item).val(value.replace(/\D/g, '').toUpperCase())

        clearTimeout(this.addressAutocompleteTimeout)

        break
    }

    this.addressAutocompleteTimeout = setTimeout(() => {
      this.addressAutocomplete()
    }, this.autocompleteDelay)
  }

  handleNumberAddition (e) {

    switch (e.type) {
      case 'keyup':
        clearTimeout(this.addressAutocompleteTimeout)

        break
    }

    this.addressAutocompleteTimeout = setTimeout(() => {
      this.addressAutocomplete()
    }, this.autocompleteDelay)
  }

  addressAutocomplete () {
    const postcode = this.postcodeElement.val()
    const number = this.numberElement.val()
    const numberAddition = this.numberAdditionElement.val()
    const loadHtml = `<div class="address-loader"></div>`;

    if (false === this.addressAutocompleteRunning && postcode.length === 6 && number) {
      $.ajax({
        method: 'POST',
        url: cvzeker.ajax_url,
        data: {
          action: 'mkoz_dutch_postcode_lookup',
          postcode: postcode,
          number: number,
          numberAddition: numberAddition
        },
        beforeSend: () => {
          this.addressAutocompleteRunning = true
          this.addressContainer.append(loadHtml);
        }
      })
        .done(this.processAddressResult.bind(this))
        .fail(this.processFailedAddressResult.bind(this))
        .always(() => {
          this.addressAutocompleteRunning = false
          $('.address-loader').remove();

          clearTimeout(this.addressAutocompleteTimeout)
        })
    }
  }

  processAddressResult (result) {
    if (Object.keys(result).length === 0 && result.constructor === Object) {
      this.streetElement.val('')
      this.cityElement.val('')
    } else if (result.hasOwnProperty('street') && result.hasOwnProperty('city')) {
      this.streetElement.val(result.street)
      this.cityElement.val(result.city)
    }
  }

  processFailedAddressResult () {
    this.streetElement.val('')
    this.cityElement.val('')
  }
};
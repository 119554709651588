import _ from 'underscore'
import validate from 'validate.js'
import AddressLookup from '../components/address-lookup'


if ( $('div').hasClass('cvz-edit-relation') ) {
  (function () {
    // These are the constraints used to validate the form
    var constraints = {
      gender_option: {
        presence: true,
      },
      first_name: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      last_name: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      phonenumber: {
        presence: true,
        length: {
          minimum: 10,
          maximum: 20
        },
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '^[0-9 - +]+$',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',

          message: 'can only contain 0-9'
        }
      },
      emailadres: {
        // Email is required
        presence: true,
        // and must be an email (duh)
        email: true
      },
      postcode: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      number: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      street: {
        presence: true,
      },
      city: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      }
    }

    // Hook up the form so we can prevent it from  being posted
    var form = document.querySelector('form#cvz-update-relation')

    if (form != null) {

      //AddressLookup call
      new AddressLookup($(form))

      $('.send-update-relation').click(function (ev) {
        ev.preventDefault()
        handleFormSubmit(form)
      })

      // Hook up the inputs to validate on the fly
      var inputs = document.querySelectorAll('input, textarea, select')
      for (var i = 0; i < inputs.length; ++i) {
        inputs.item(i).addEventListener('change', function (ev) {
          var errors = validate(form, constraints) || {}
          showErrorsForInput(this, errors[this.name])
        })
      }
    }

    function handleFormSubmit (form, input) {
      // validate the form aainst the constraints
      var errors = validate(form, constraints)
      // then we update the form to reflect the results
      showErrors(form, errors || {})
      if (!errors) {
      }
    }

    // Updates the inputs with the validation errors
    function showErrors (form, errors) {
      // We loop through all the inputs and show the errors for that input
      _.each(form.querySelectorAll('input[name], select[name]'), function (input) {
        // Since the errors can be null if no errors were found we need to handle
        // that
        showErrorsForInput(input, errors && errors[input.name])
      })
    }

    // Shows the errors for a specific input
    function showErrorsForInput (input, errors) {

      // we first mark the group has having errors
      var formGroup = closestParent(input.parentNode, 'mcw_form_validate')

      // First we remove any old messages and resets the classes
      resetFormGroup(formGroup)

      if (errors) {
        // If we have errors
        formGroup.classList.add('has-error')
        _.each(errors, function (error) {
          if ($(formGroup).hasClass('mcw_form_password_row')) {
            $(formGroup).find('.messages').html(error)
          }
        })
      } else {
        // otherwise we simply mark it as success
        formGroup.classList.add('has-success')
      }
    }

    // Recusively finds the closest parent that has the specified class
    function closestParent (child, className) {
      if (!child || child == document) {
        return null
      }
      if (child.classList.contains(className)) {
        return child
      } else {
        return closestParent(child.parentNode, className)
      }
    }

    function resetFormGroup (formGroup) {
      // Remove the success and error classes
      formGroup.classList.remove('has-error')
      formGroup.classList.remove('has-success')
      // and remove any old messages
      _.each(formGroup.querySelectorAll('.help-block.error'), function (el) {
        el.parentNode.removeChild(el)
      })
    }

    // Adds the specified error with the following markup
    // <p class="help-block error">[message]</p>
    function addError (messages, error) {
      var block = document.createElement('p')
      block.classList.add('help-block')
      block.classList.add('error')
      block.innerText = error
      messages.appendChild(block)
    }


  })()
}

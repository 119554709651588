'use strict';

function topMenu() {

}

topMenu.prototype.initialize = function() {
  this.menu();
};

topMenu.prototype.menu = function() {
  $(".mcw_top_menu").on("click", ".one", function(e) {
    window.location.href=window.location.href;
  });
};

module.exports = new topMenu();
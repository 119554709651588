(function () {
  const localStorage = window.localStorage
  const paymentOption = $('input:radio[name="payment_option"]')
  const element = $('#mcw-payment-confirm')
  const firstID = element.attr('data-first-id')
  const secondID = element.attr('data-second-id')
  const thirdID = element.attr('data-third-id')
  const fourthID = element.attr('data-fourth-id')
  const pricetotal = element.attr('data-price')
  const assemble = element.attr('data-assemble')

  //relation data
  const genderElement = localStorage.getItem('genderOption')
  const firstNameElement = localStorage.getItem('firstName')
  const lastNameElement = localStorage.getItem('lastName')
  const emailAddressElement = localStorage.getItem('emailAddress')
  const phoneNumberElement = localStorage.getItem('phoneNumber')
  const postcodeElement = localStorage.getItem('postcode')
  const numberElement = localStorage.getItem('number')
  const numberAdditionElement = localStorage.getItem('numberAddition')
  const streetElement = localStorage.getItem('street')
  const cityElement = localStorage.getItem('city')
  const internalRemarkElement = localStorage.getItem('internalRemark')
  const password = localStorage.getItem('password')

  const price = localStorage.getItem('excl_price')
  const orderType = localStorage.getItem('order-type')
  const questions = JSON.parse(window.localStorage.getItem('questions'))
  const offer = JSON.parse(window.localStorage.getItem('mcw-offer'))
  const paymentPeriodElement = localStorage.getItem('payment')
  let description = ''

  $.each(questions, function (i, item) {
    $.each(item.answers, function (i, arrayanswer) {
      if (arrayanswer.product !== null && arrayanswer.hasOwnProperty('product')) {
        description = description + arrayanswer.product.name + ' '
      }
    })
  })

  if (description == '') {
    description = 'Ketel'
  }

  $('#mcw-payment-confirm').click(function (ev) {
    ev.preventDefault()

    if (orderType === 'maintenance') {
      handleOnderhoudSubmit()
    } else if (orderType === 'product') {
      handleWoocommerceSubmit()
    }
  })

  //Afvoeren van de Onderhoudskiezer gegevens naar MKOZ
  function handleOnderhoudSubmit () {
    const date = new Date().getTime()
    const loadHtml = `<div class="address-loader"></div>`
    const errorHtml = `<p>Er is een probleem opgetreden met het controleren van de gegevens. Probeer het opnieuw.</p>`

      $.ajax({
        method: 'POST',
        url: cvzeker.ajax_url,
        data: {
          action: 'mkoz_maintenance',
          gender: genderElement,
          firstName: firstNameElement,
          lastName: lastNameElement,
          emailAddress: emailAddressElement,
          phoneNumber: phoneNumberElement,
          postcode: postcodeElement,
          number: numberElement,
          numberAddition: numberAdditionElement,
          street: streetElement,
          city: cityElement,
          internalRemark: internalRemarkElement,
          paymentPeriod: paymentPeriodElement,
          price: price,
          questions: questions,
          offer: offer,
        },
        beforeSend: () => {
          $('#mcw-payment-confirm').html(loadHtml)

          $('.error-message').html('')
        }
      })
        .done(() => {
          localStorage.clear();
          window.location.replace('/onderhoud-afronden');
        })
        .fail(() => {
          $('.error-message').html(errorHtml)
        })
        .always(() => {
          $('#mcw-payment-confirm').html('Afronden')
        })
  }

  //Afvoeren van de Woocommerce product gegevens naar MKOZ
  function handleWoocommerceSubmit () {
    const date = new Date().getTime()
    const loadHtml = `<div class="address-loader"></div>`
    const errorHtml = `<p>Er is een probleem opgetreden met het controleren van de gegevens. Probeer het opnieuw.</p>`

    if (localStorage.getItem('check-out-type') === 'quotation') {
      $.ajax({
        method: 'POST',
        url: cvzeker.ajax_url,
        data: {
          action: 'mkoz_quotations_wc',
          relation: {
            gender: genderElement,
            firstName: firstNameElement,
            lastName: lastNameElement,
            emailAddress: emailAddressElement,
            phoneNumber: phoneNumberElement,
            postcode: postcodeElement,
            number: numberElement,
            numberAddition: numberAdditionElement,
            street: streetElement,
            city: cityElement,
            password: password
          },
          quotation: {
            reference: 'Product - ' + date,
            price: pricetotal,
            internalRemark: internalRemarkElement,
            firstID: firstID,
            secondID: secondID,
            thirdID: thirdID,
            fourthID: fourthID
          },
        },
        beforeSend: () => {
          $('#mcw-payment-confirm').html(loadHtml)

          $('.error-message').html('')
        }
      })
        .done(() => {
          localStorage.clear();
          window.location.replace('/offerte-afronden');
        })
        .fail(() => {
          $('.error-message').html(errorHtml)
        })
        .always(() => {
          $('#mcw-payment-confirm').html('Afronden')
        })
    }

    if (localStorage.getItem('check-out-type') === 'order') {
      $.ajax({
        method: 'POST',
        url: cvzeker.ajax_url,
        data: {
          action: 'mkoz_orders_wc',
          relation: {
            gender: genderElement,
            firstName: firstNameElement,
            lastName: lastNameElement,
            emailAddress: emailAddressElement,
            phoneNumber: phoneNumberElement,
            postcode: postcodeElement,
            number: numberElement,
            numberAddition: numberAdditionElement,
            street: streetElement,
            city: cityElement,
            password: password,
          },
          order: {
            reference: 'Product - ' + date,
            price: pricetotal,
            internalRemark: internalRemarkElement,
            firstID: firstID,
            secondID: secondID,
            thirdID: thirdID,
            fourthID: fourthID,
            assemble: assemble,
          },
        },
        beforeSend: () => {
          $('#mcw-payment-confirm').html(loadHtml)

          $('.error-message').html('')
        }
      })
        .done(function (list) {
          if (list.paymentUrl) {
            localStorage.clear();
            window.location.replace(list.paymentUrl)
          } else {
            localStorage.clear();
            $('form#mcw-payment-option').submit()
          }
        })
        .fail(function (error) {
          // $('.error-message').html(list)

          const errorMessage = JSON.stringify(error.responseJSON)
          $('.error-message').html(errorMessage)
        })
        .always(() => {
          $('#mcw-payment-confirm').html('Afronden')
        })
    }
  }
})()
import _ from 'underscore'
import validate from 'validate.js'
import AddressLookup from '../components/address-lookup'

(function () {
  //relation data
  const genderElement = $('input:radio[name="gender_option"]')
  const firstNameElement = $('input[name="first_name"]')
  const lastNameElement = $('input[name="last_name"]')
  const emailAddressElement = $('input[name="emailadres"]')
  const phoneNumberElement = $('input[name="phonenumber"]')
  const postcodeElement = $('input[name="postcode"]')
  const numberElement = $('input[name="number"]')
  const numberAdditionElement = $('input[name="numberAddition"]')
  const streetElement = $('input[name="street"]')
  const cityElement = $('input[name="city"]')
  const passwordElement = $('input[name="password"]')
  const internalRemarkElement = $('textarea[name="comments"]')

  // These are the constraints used to validate the form
  var constraints = {
    gender_option: {
      presence: true,
    },
    first_name: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    },
    last_name: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    },
    phonenumber: {
      presence: true,
      length: {
        minimum: 10,
        maximum: 20
      },
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '^[0-9 - +]+$',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',

        message: 'can only contain 0-9'
      }
    },
    emailadres: {
      // Email is required
      presence: true,
      // and must be an email (duh)
      email: true
    },
    postcode: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    },
    number: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    },
    street: {
      presence: true,
    },
    city: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    }
  }

  // These are the constraints used to validate the form
  var passwordConstraints = {
    password: {
      presence: {
        allowEmpty: false,
        message: 'veld moet ingevuld worden.',
      },

      length: {
        minimum: 8,
        message: 'veld moet minimaal 8 tekens bevatten.'
      }
    }
  }

  if (window.localStorage.getItem('order-type') === 'product' && window.localStorage.getItem('check-out-type') === 'order') {
    $.extend( constraints, passwordConstraints )
  }


  // Hook up the form so we can prevent it from  being posted
  var form = document.querySelector('form#mcw_form_information')

  if (form != null) {
  //AddressLookup call
  new AddressLookup($(form))

  //Terug halen van data
  let searchParams = new URLSearchParams(window.location.search)

  let backStatus = searchParams.get('back')

  if (backStatus != null) {
    dataBack()
  }

  //dataBack();

  $('#confirm-2').click(function (ev) {
    ev.preventDefault()
    handleFormSubmit(form)
  })


    // Hook up the inputs to validate on the fly
    var inputs = document.querySelectorAll('input, textarea, select')
    for (var i = 0; i < inputs.length; ++i) {
      inputs.item(i).addEventListener('change', function (ev) {
        var errors = validate(form, constraints) || {}
        showErrorsForInput(this, errors[this.name])
      })
    }
  }

  function handleFormSubmit (form, input) {
    // validate the form aainst the constraints
    var errors = validate(form, constraints)
    var passwordErrors = validate(form, passwordConstraints)
    // then we update the form to reflect the results
    showErrors(form, errors || {})
    if (!errors) {
      showSuccess()
    }
  }

  // Updates the inputs with the validation errors
  function showErrors (form, errors) {
    // We loop through all the inputs and show the errors for that input
    _.each(form.querySelectorAll('input[name], select[name]'), function (input) {
      // Since the errors can be null if no errors were found we need to handle
      // that
      showErrorsForInput(input, errors && errors[input.name])
    })
  }

  // Shows the errors for a specific input
  function showErrorsForInput (input, errors) {

    // we first mark the group has having errors
    var formGroup = closestParent(input.parentNode, 'mcw_form_validate')

    // First we remove any old messages and resets the classes
    resetFormGroup(formGroup)

    if (errors) {
      // If we have errors
      formGroup.classList.add('has-error')
      _.each(errors, function (error) {
        if ($(formGroup).hasClass('mcw_form_password_row')) {
          $(formGroup).find('.messages').html(error)
        }
      })
    } else {
      // otherwise we simply mark it as success
      formGroup.classList.add('has-success')
    }
  }

  // Recusively finds the closest parent that has the specified class
  function closestParent (child, className) {
    if (!child || child == document) {
      return null
    }
    if (child.classList.contains(className)) {
      return child
    } else {
      return closestParent(child.parentNode, className)
    }
  }

  function resetFormGroup (formGroup) {
    // Remove the success and error classes
    formGroup.classList.remove('has-error')
    formGroup.classList.remove('has-success')
    // and remove any old messages
    _.each(formGroup.querySelectorAll('.help-block.error'), function (el) {
      el.parentNode.removeChild(el)
    })
  }

  // Adds the specified error with the following markup
  // <p class="help-block error">[message]</p>
  function addError (messages, error) {
    var block = document.createElement('p')
    block.classList.add('help-block')
    block.classList.add('error')
    block.innerText = error
    messages.appendChild(block)
  }

  function showSuccess () {
    var localStorage = window.localStorage

    localStorage.setItem('genderOption', genderElement.val())
    localStorage.setItem('firstName', firstNameElement.val())
    localStorage.setItem('lastName', lastNameElement.val())
    localStorage.setItem('emailAddress', emailAddressElement.val())
    localStorage.setItem('phoneNumber', phoneNumberElement.val())
    localStorage.setItem('postcode', postcodeElement.val())
    localStorage.setItem('number', numberElement.val())
    localStorage.setItem('numberAddition', numberAdditionElement.val())
    localStorage.setItem('street', streetElement.val())
    localStorage.setItem('city', cityElement.val())
    localStorage.setItem('password', passwordElement.val())
    localStorage.setItem('internalRemark', internalRemarkElement.val())

    $('form#mcw_form_information').submit()

  }

  function dataBack () {
    const localStorage = window.localStorage
    const genderElement = localStorage.getItem('genderOption')
    const firstNameElement = localStorage.getItem('firstName')
    const lastNameElement = localStorage.getItem('lastName')
    const emailAddressElement = localStorage.getItem('emailAddress')
    const phoneNumberElement = localStorage.getItem('phoneNumber')
    const postcodeElement = localStorage.getItem('postcode')
    const numberElement = localStorage.getItem('number')
    const numberAdditionElement = localStorage.getItem('numberAddition')
    const streetElement = localStorage.getItem('street')
    const cityElement = localStorage.getItem('city')
    const internalRemarkElement = localStorage.getItem('internalRemark')

    $( 'input#first_name' ).val( firstNameElement )
    $( 'input#last_name' ).val( lastNameElement )
    $( 'input#emailadres' ).val( emailAddressElement )
    $( 'input#phone' ).val( phoneNumberElement )
    $( 'input#postcode' ).val( postcodeElement )
    $( 'input#number' ).val( numberElement )
    $( 'input#numberAddition' ).val( numberAdditionElement )
    $( 'input#street' ).val( streetElement )
    $( 'input#city' ).val( cityElement )
    $( 'textarea' ).val( internalRemarkElement )


    $('input:radio[name="gender_option"][value="' + genderElement + '"]').prop( 'checked', true );

  }
})()

'use strict'

import '../scss/app.scss'
import '../img/logo.png'
import '../img/logo.svg'
import '../img/logo-dia.svg'
import '../img/google-maps.png'
import '../img/ketel.png'
import '../img/thermo.jpg'
import logo_flame from '../img/logo-flame.png'
const maintenancewizardbox = require('../img/maintenance-wizard-box.png')

import './cvz-mcw/mcw-form-choose-package'
import './cvz-mcw/mcw-form-validate'
import './shared/scroll'
import './components/handlePayments'
import './components/handleMaintenancePlanner'
import './components/handleForgotPassword'
import './components/collapse'
import './components/myCvzekerValidate'
import 'jquery'
import AddressLookup from './components/address-lookup'

const menu = require('./shared/menu')
const topMenu = require('./cvz-mcw/topMenu')
const gfDatePicker = require('./components/gfDatePicker')
const productPriceUpdate = require('./components/productPriceUpdate')
const myCvzeker = require('./components/myCvzeker')
const productSEO = require('./components/productSEO')

/**
 * MkozWordpressTheme class
 *
 * @constructor
 */
class CvZeker {

  constructor () {
    this.currentQuestion = null
    this.previousQuestion = []
    this.lastPreviousQuestion = []

    menu.initialize()
    topMenu.initialize()
    productPriceUpdate.initialize()
    myCvzeker.initialize()
    productSEO.initialize()

    this.productTabs()
    this.bindEvents()
    this.updatePaymentList()

    var lastScrollTop = 0;
    $(window).scroll(function(event){
      var st = $(this).scrollTop();
      if (st > lastScrollTop){
        // downscroll code
      } else {
        // upscroll code
      }
      lastScrollTop = st;
    });

    $('.cvz-fm-container').stop().delay(800).animate({right: '0px'})
    $('#tab-additional_information').addClass('current')

    $("#gform_submit_button_1").click(function () {
      $('form#gform_1').submit()
    });

    $("#gform_submit_button_3").click(function () {
      $('form#gform_3').submit()
    });

    $("#gform_submit_button_13").click(function () {
      $('form#gform_13').submit()
    });

    if ( $('div').hasClass('gfAutofillAddress') ) {
      var form = document.querySelector('.gform_wrapper form')
      //AddressLookup call
      new AddressLookup($(form))
    }

    //change winkelmand top title
    if (window.localStorage.getItem('order-type') === 'product' && window.localStorage.getItem('check-out-type') === 'quotation') {
      $('.mcw_top h2 span').html('Vraag hier jouw persoonlijke offerte aan')
      $('.password-row').html('')
      $('#mcw-payment-confirm').html('Offerte aanvraag')
      $('.shopping-cart .entry-shopping-cart-inner').addClass('hide-block')
      $('.quotation-cart').addClass('show')
      $('.shopping-cart .cvz-mcw-payment-total-price').addClass('hide-block')
      $('.check-out .cvz-mcw-payment-total-price').addClass('hide-block')
      $('.shopping-cart #mcw_payment').addClass('offerte-aanvraag')
    } else if (window.localStorage.getItem('order-type') === 'product' && window.localStorage.getItem('check-out-type') === 'order') {
      $('.mcw_top h2 span').html('Jouw persoonlijke bestelling')
      $('.mcw-winkelmand-text').html('Wij gaan altijd zorgvuldig om met jouw persoonlijke gegevens en zullen deze nooit delen met andere partijen. Wij zullen zo snel mogelijk de bestelling naar jouw toezenden. Mochten er toch nog opmerkingen en of vragen zijn dan horen wij dat uiteraard graag.')
      $('.cvz-mcw-container-inner .cvz-mcw-payment-total-price').addClass( "show" )
    } else if (window.localStorage.getItem('order-type') === 'maintenance' && window.localStorage.getItem('mcw-type') === 'quotation') {
      $('.mcw_top h2 span').html('Vraag hier jouw persoonlijke offerte aan')
      $('.password-row').html('')
      $('#mcw-payment-confirm').html('Offerte aanvraag')
      $('.cvz-mcw-payment-total-price .monthly').html('')
    } else if (window.localStorage.getItem('order-type') === 'maintenance' && window.localStorage.getItem('mcw-type') === 'order') {
      $('.mcw_top h2 span').html('Jouw persoonlijke bestelling')
      $('.mcw-winkelmand-text').html('Wij gaan altijd zorgvuldig om met jouw persoonlijke gegevens en zullen deze nooit delen met andere partijen. Wij zullen zo snel mogelijk de bestelling naar jouw toezenden. Mochten er toch nog opmerkingen en of vragen zijn dan horen wij dat uiteraard graag.')
      $('.cvz-mcw-container-inner .cvz-mcw-payment-total-price').addClass( "show" )
      $('.cvz-mcw-payment-total-price .monthly').html('')
    }


  }

  bindEvents () {
    $('[data-action="maintenance-contract-wizard"]').on('click', e => {
      e.preventDefault()

      this.showMaintenanceContractWizard()

    })

    //restart modal
    $('body').on('click', '.mcw-restart', (e) => {
      e.preventDefault()

      const self = this

      $('body').css('overflow', '')
      $('.mcw-modal').stop().animate({right: '-100%'})

      setTimeout(function () {
        $('.mcw-modal').remove()

        self.showMaintenanceContractWizard()
      }, 800)

    })

  }

  showMaintenanceContractWizard (response) {
    const modelTopRightHtml = `<span id="prevBtn"><i class="icon-arrow-left icons"></i>Naar laatste vraag</span><span data-action="mcw-restart">Herstart</span>`
    const modalHtml = `<div class="mcw-modal"><div class="mcw-modal-container"><div class="mcw-modal-top"><span data-action="mcw-modal-close"><i class="fa fa-times" aria-hidden="true"></i></span><div class="modal-top-right">${modelTopRightHtml}</div></div><div class="mcw-modal-loading"></div><div class="mcw-modal-content"></div></div></div>`
    const loadHtml = `<div class="loading-screen loading-start" style="display: block"> <div class="loading-screen-inner"><h2>Op naar je ideale pakket!</h2><div class="loading-flame"><div class="cv-static-checkmark"><div class="static-circle"><div class="static-checkmark"></div></div><div class="static-text"></div></div></div><div class="circle-sub-loader"></div></div></div>`
    const errorHtml = `<i class="fa fa-exclamation-circle" aria-hidden="true"></i> Beantwoord eerst deze vraag voordat je verder gaat`

    const self = this

    let maintenanceWizardQuestions = []
    let countQuestion = null
    let countQuestionWidth = null
    window.localStorage.setItem('payment', '')

    //disable scroll en animate
    $('body').append(modalHtml)
    $('body').css('overflow', 'hidden')
    $('.mcw-modal').stop().animate({right: '0px'})

    $('.mcw-modal').on('click', '#nextBtn', (e) => {
      let gotoQuestion = $('input:checked', '.mcw_form_answers').attr('data-goto-question')
      const answerType = $('input:checked', '.mcw_form_answers').attr('data-answer-type')

      const current = maintenanceWizardQuestions[this.currentQuestion]
      const isRequired = current.isRequired
      const type = current.type

      if (isRequired === false && !$('.tab').find('input').is(':checked') || type === 'information') {
        gotoQuestion = current.gotoQuestion
      }

      window.localStorage.setItem('order-type', 'maintenance')

      if ($('.tab').find('input').is(':checked') || isRequired === false || type === 'information') {
        $('.mcw_form .error_message').html('')
        $('#nextBtn').attr('disabled', false)
        $('#prevBtn').attr('disabled', false)


        setTimeout(function () {
          const choice = $('body .tab').find('input:checked')
          this.saveAnswer(this.currentQuestion, choice, (answerType === 'single_choice'), current.question)

          if (typeof(gotoQuestion) === 'undefined' || typeof(maintenanceWizardQuestions[gotoQuestion]) === 'undefined') {
            this.previousQuestion[maintenanceWizardQuestions[this.currentQuestion].id] = this.currentQuestion

            this.endLoading()

            this.showProductOverview()
          } else {
            this.lastPreviousQuestion = this.currentQuestion
            this.previousQuestion[maintenanceWizardQuestions[gotoQuestion].id] = this.currentQuestion
            this.showLoadingScreen('.loading-between')

            this.showMaintenanceContractWizardQuestion(maintenanceWizardQuestions[gotoQuestion])
          }

          if (countQuestion) {
            countQuestionWidth = countQuestionWidth + (100 / countQuestion)

            $('.step').width(countQuestionWidth + '%')
          }
        }.bind(this), 200)
      } else {
        $('.mcw_form .error_message').html(errorHtml)
      }
    })

    $('body').on('click', '.mcw-order', (e) => {
      window.localStorage.setItem('mcw-type', 'order')
    })

    $('body').on('click', '.mcw-quotation', (e) => {
      window.localStorage.setItem('mcw-type', 'quotation')
    })

    $('body').on('change', 'input:radio[name="payment"]', function () {
      const value = $(this).val()
      if (value === 'per-month') {
        window.localStorage.setItem('payment', 'monthly')
        self.updatePaymentList(value)
      } else if (value === 'per-year') {
        window.localStorage.setItem('payment', 'yearly')
        self.updatePaymentList(value)
      }
    });

    $('body').on('change', 'input:radio[name="recommendation"]', function () {
      const recommendation = $(this).val()

      window.localStorage.setItem('mcw-type', 'order')
      if (recommendation === 'recommendation-no') {
        window.localStorage.setItem('mcw-offer', null)
        $('#recommendation').remove()
        self.updatePaymentList()
      } else if (recommendation === 'recommendation-yes') {
        const offer = {
          id: $(this).attr('data-product-id'),
          name: $(this).attr('data-product-name'),
          price: $(this).attr('data-product-price') != null ? $(this).attr('data-product-price') : null,
          recommended_price: $(this).attr('data-product-recommended-price') != null ? $(this).attr('data-product-recommended-price') : null,
          excl_price: $(this).attr('data-product-excl-price') != null ? $(this).attr('data-product-excl-price') : null
        }


        window.localStorage.setItem('mcw-offer', JSON.stringify(offer))
        self.updatePaymentList()
      }
    });

    $('.mcw-modal').on('click', '#prevBtn', (e) => {
      if (null !== this.previousQuestion) {
        let gotoQuestion = this.previousQuestion[this.currentQuestion]

        if (gotoQuestion === this.currentQuestion) {
          this.previousQuestion[maintenanceWizardQuestions[gotoQuestion].id] = this.lastPreviousQuestion
        }

        $('.mcw_form .error_message').html('')

        this.showMaintenanceContractWizardQuestion(maintenanceWizardQuestions[gotoQuestion])
        this.resetProductOverview()

        if (countQuestion) {

          countQuestionWidth = countQuestionWidth - (100 / countQuestion)

          $('.step').width(countQuestionWidth + '%')
        }
      }
    })

    $.ajax({
      cache: false,
      url: '/onderhoudskiezer',
      type: 'GET',
      beforeSend: function (xhr) {
        $('.mcw-modal-loading').append(loadHtml)
      }
    }).done(function (response) {
      $.ajax({
        method: 'GET',
        dataType: 'json',
        url: cvzeker.ajax_url,
        data: {
          action: 'mkoz_maintenance_wizard_list',
        }
      }).done(function (list) {
        $('.mcw-modal-content').append(response)
        $('.mcw-modal-loading').remove()

        maintenanceWizardQuestions = list
        countQuestion = Object.keys(list).length
        countQuestionWidth = (100 / countQuestion)

        const firstQuestion = maintenanceWizardQuestions[Object.keys(maintenanceWizardQuestions)[0]]

        self.showMaintenanceContractWizardQuestion(firstQuestion, firstQuestion.id)
      })

    }).fail(function () {
      // nette foutmelding tonen, probeer het nogmaals
      $('.mcw-modal-content').append('<h2>Er is een probleem opgetreden met het openen van de onderhoudskiezer</h2><p>Probeer het opnieuw.</p>')
    }).always(function () {
    })

    $('body').on('click', '.cvz-mcw-payment-button-container input', (e) => {
    })

    //close modal
    $('[data-action="mcw-modal-close"]').on('click', e => {
      e.preventDefault()

      $('body').css('overflow', '')
      $('.mcw-modal').stop().animate({right: '-100%'})

      setTimeout(function () {
        $('input:radio').removeAttr('checked');
        $('input:checkbox').removeAttr('checked');
        $('.mcw-modal').remove()
      }, 800)
    })
  }

  saveAnswer (currentQuestion, answerInputs, single, questionText) {
    let questions = window.localStorage.getItem('questions')
    let answers = []

    if (questions === null) {
      questions = []
    } else {
      questions = JSON.parse(questions)
    }

    let question = {
      id: currentQuestion,
      question: questionText,
      answers: []
    }

    if (answerInputs.length > 0) {
      $.each(answerInputs, function (i, answer) {
        const item = {
          answer: $(answer).val(),
          product: null
        }

        if ($(answer).attr('data-product-id') !== 'null') {
          item.product = {
            id: $(answer).attr('data-product-id'),
            name: $(answer).attr('data-product-name'),
            price: $(answer).attr('data-product-price'),
            recommended_price: $(answer).attr('data-product-recommended-price') != null ? $(answer).attr('data-product-recommended-price') : null,
            excl_price: $(answer).attr('data-product-excl-price') != null ? $(answer).attr('data-product-excl-price') : null
          }
        }

        answers.push(item)
      })
    } else {
      answers.push({
        answer: null
      })
    }

    question.answers = answers

    let currentQuestionIndex = this.getQuestionIndex(currentQuestion)

    if (currentQuestionIndex >= 0) {
      if (single === true) {
        if (questions[currentQuestionIndex].answers[0].answer != question.answers[0].answer) {
          questions = questions.slice(0, currentQuestionIndex + 1)
        }
      }

      questions[currentQuestionIndex] = question
    } else {
      questions.push(question)
    }

    window.localStorage.setItem('questions', JSON.stringify(questions))
  }

  getQuestion (questionId) {
    let question
    let questions = JSON.parse(window.localStorage.getItem('questions'))

    if (questions === null) {
      questions = []
    }

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].id !== questionId) {
        continue
      }

      question = questions[i]
    }

    return question
  }

  getQuestionIndex (questionId) {
    let index
    let questions = JSON.parse(window.localStorage.getItem('questions'))

    if (questions === null) {
      questions = []
    }

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].id !== questionId) {
        continue
      }

      index = i
    }

    return index
  }

  showMaintenanceContractWizardQuestion (question) {
    let questions = JSON.parse(window.localStorage.getItem('questions'))

    this.currentQuestion = question.id

    const buttonPrev = '<button type="button" id="prevBtn" class="cvz-btn cvz-btn-orange">Vorige</button>'
    const buttonNext = '<button type="button" id="nextBtn" class="cvz-btn cvz-btn-orange">Volgende</button>'

    this.getQuestion(this.currentQuestion)

    $('.mcw_form h2').html(question.question)
    $('.mcw_form .description').html(question.description)
    $('.mcw_form .mcw_form_buttons').html('').append(buttonPrev, buttonNext)
    $('.mcw_form .mcw_form_answers').html('')

    if (question.type !== 'information') {
      if (question.answerType === 'single_choice') {
        $.each(question.answers, function (i, item) {
          const answers = `<div class="${ item.image !== null ? 'img-no-text' : '' } mcw_form_answer ${question.id}"><input type="radio" name="${question.id}" value="${item.label}" id="${item.id}" data-answer-type="${question.answerType}" data-goto-question="${ item.hasOwnProperty('gotoQuestion') ? item.gotoQuestion : null}" data-product-price="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('price') ? item.product.price : null }" data-product-excl-price="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('price_exclusive') ? item.product.price_exclusive : null}" data-product-recommended-price="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('recommended_price') ? item.product.recommended_price : null}" data-product-name="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('name') ? item.product.name : null}" data-product-id="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('id') ? item.product.id : null}"><label for='${item.id}'>${ item.image !== null ? '<img src="' + item.image + '">' : item.label } </label> ${ item.information !== null ? '<div class="mcw_form_info_box">i<span class="tooltiptext">' + item.information + '</span></div>' : '' }</div>`
          $('.mcw_form .mcw_form_answers').append(answers)
        })
      } else {
        $.each(question.answers, function (i, item) {
          const answers = `<div class="${ item.image !== null ? 'img-no-text' : '' } mcw_form_answer ${question.id}"><input type="checkbox" name="${item.id}" value="${item.label}" id="${item.id}" data-answer-type="${question.answerType}" data-goto-question="${ item.hasOwnProperty('gotoQuestion') ? item.gotoQuestion : null}" data-product-price="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('price') ? item.product.price : null}" data-product-excl-price="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('price_exclusive') ? item.product.price_exclusive : null}" data-product-recommended-price="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('recommended_price') ? item.product.recommended_price : null}" data-product-name="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('name') ? item.product.name : null}" data-product-id="${ item.hasOwnProperty('product') && null !== item.product && item.product.hasOwnProperty('id') ? item.product.id : null}"><label for='${item.id}'>${ item.image !== null ? '<img src="' + item.image + '">' : item.label } </label></div>`
          $('.mcw_form .mcw_form_answers').append(answers)
        })
      }
    }

    $.each(questions, function (i, item) {
      if (item.answers.length > 1) {
        $.each(item.answers, function (i, answer) {
          $('.' + item.id).find('input[value="' + answer.answer + '"]').prop('checked', true)
        })
      } else {
        $('.' + item.id).find('input[value="' + item.answers[0].answer + '"]').prop('checked', true)
      }
    })
  }

  showProductOverview () {
    let questions = JSON.parse(window.localStorage.getItem('questions'))

    const modelTopRightHtml = `<span id="prevBtn"><i class="icon-arrow-left icons"></i>Naar laatste vraag</span><span class="mcw-restart">Herstart</span>`
    const productPackage = `<div class="mcw_form_package_wrapper">
                            <h2>Kies jij ook voor ons?</h2>
                            <strong>Op basis van jouw wensen, stelde wij dit pakket voor je samen!</strong>
                            <div class="mcw_form_package_inner">
                                <div class="mcw_form_package_img cvz-mcw-col col-md-4">
                                    <img src="`+ maintenancewizardbox +`">
                                </div>
                                <div class="mcw_form_package_text cvz-mcw-col col-md-8">
                                    <p>Zit er lekker warm bij met dit onderhouds pakket.</p>
                                    
                                    <div class="mcw_form_package_text_list">
                                    
                                    </div>

                                    <br>

                                    <button type="button" class="cvz-btn cvz-btn-orange mcw-restart">Wijzig pakket
                                    </button>
                                </div>
                            </div>
                        </div>`


    $('input:radio[name="payment"][value="per-month"]').prop('checked', true)
    $('input:radio[name="recommendation"][value="recommendation-no"]').prop('checked', true)
    $('input:radio[name="recommendation"][value="recommendation-no"]').trigger('change');
    $('.modal-top-right').html(modelTopRightHtml)
    $('.mcw_form h2').html('')
    $('.mcw_form .description').html('')
    $('.mcw_form .mcw_form_buttons').html('')
    $('.mcw_form .mcw_form_answers').html('')

    $('#mcw_form').addClass('col-md-8')
    $('#mcw_payment').addClass('show')
    $('.mcw_offer').addClass('show-block')
    $('#cvz-mcw-container').addClass('mcw_form_package')

    $('#mcw_form').removeClass('col-md-12')
    $('#cvz-mcw-container').removeClass('mcw_form_steps')
    $('.modal-top-right').css('display', 'block')

    $('.mcw_form .mcw_package').html(productPackage)


    $.each(questions, function (i, item) {
      $.each(item.answers, function (i, arrayanswer) {
        let count = null
        if (arrayanswer.product !== null && arrayanswer.hasOwnProperty('product')) {
          $('body .mcw_form_package_text_list').append('<li> ' + arrayanswer.product.name+ '</li>')
        }
      })
    })

    this.updatePaymentList()
  }

  updatePaymentList (value) {
    if (window.localStorage.getItem('order-type') === 'maintenance') {
      let questions = JSON.parse(window.localStorage.getItem('questions'))
      let answers = []
      let exclPrice = 0.0
      let recommendedPrice = 0.0
      let totalPrice = 0.0
      let profitPrice = 0.0
      let offer = null
      if (window.localStorage.getItem('mcw-offer') != '' ||window.localStorage.getItem('mcw-offer') != null ) {
        offer = JSON.parse(window.localStorage.getItem('mcw-offer'))
      }

      $('body .shopping-cart-body #mcw_payment_lists').html('')
      $('body .mcw_order_check_package #mcw_payment_lists').html('')
      $('body .mcw_offer_price').html('')

      const perMonth = `<div><span><strong>Totale kosten per maand</strong></span><span class="total-price-description">op basis van automatisch incasso</span></div>`
      const perYear = `<div><span><strong>Totale kosten per jaar</strong></span><span class="total-price-description">op basis van automatisch incasso</span></div>`

      $.each(questions, function (i, item) {
        $.each(item.answers, function (i, arrayanswer) {
          answers.push(arrayanswer)
          if (arrayanswer.product !== null && arrayanswer.hasOwnProperty('product')) {
            let num = parseFloat(arrayanswer.product.price).toFixed(2)
            let recommended_num = parseFloat(arrayanswer.product.recommended_price).toFixed(2)
            let excl_num = parseFloat(arrayanswer.product.excl_price).toFixed(2)

            exclPrice = (parseFloat(excl_num) + exclPrice)
            recommendedPrice = (parseFloat(recommended_num) + recommendedPrice)
            totalPrice = (parseFloat(num) + totalPrice)
          }
        })
      })

      $.each(answers, function (i, item) {
        if (item.product !== null && item.hasOwnProperty('product')) {
          const answer = `<tr id="${ item.answer }"> <td id="title"><p>${ item.product !== null ? item.product.name : ''}</p></td>${ item.product !== null ? '<td id="price"><p>€ ' + parseFloat(item.product.price).toFixed(2) + '</p></td></tr>' : '<td id="price"><p></p></td></tr>' }`
          //const answer = `<tr id="${ item.answer }"> <td id="title"><p>${ item.product !== null ? item.product.name : ''}</p></td>${ item.product !== null ? '<td id="price"><p></p></td></tr>' : '<td id="price"><p></p></td></tr>' }`
          $('body .cvz-mcw-container #mcw_payment_lists').append(answer)
        }
      })

      if (offer !== null) {
        $('.cvz-mcw-payment-total-price').append(`<table class="mcw_offer_price"><tbody><tr><td><div><span><strong>Persoonlijke aanbieding</strong></span><span class="total-price-description">`+ offer.name + `</span></div></td><td style="text-align: right;" ><span><strong>€ ` + parseFloat(offer.price).toFixed(2) + `</strong></span><span class="total-price-description">inclusief btw</span></td></tr></tbody></table>`)
        $('body .cvz-mcw-payment-total-price .cvz-mcw-payment-total-price .mcw_offer_price').html('')
      }

      if (totalPrice > recommendedPrice || isNaN(recommendedPrice)) {
        recommendedPrice = totalPrice
      }

      if (value === 'per-year' ||  window.localStorage.getItem('payment') === 'yearly') {
        let exclPricePY = exclPrice * 12;
        let totalPricePY = totalPrice * 12;
        let recommendedPricePY = recommendedPrice * 12;

        if ($(offer).length > 0) {
          // exclPrice = (parseFloat(offer.excl_price) + exclPrice)
          // recommendedPrice = (parseFloat(offer.recommended_price) + recommendedPrice)
          // totalPrice = (parseFloat(offer.price) + totalPrice)
          //
          // exclPricePY = (parseFloat(offer.excl_price) + exclPricePY)
          // recommendedPricePY = (parseFloat(offer.recommended_price) + recommendedPricePY)
          // totalPricePY = (parseFloat(offer.price) + totalPricePY)
        } else {

        }


        profitPrice = recommendedPrice - totalPrice

        if ($(offer).length > 0) {
          profitPrice +=  (parseFloat(offer.recommended_price)) - (parseFloat(offer.price))
        }
        window.localStorage.setItem('payment', 'yearly')
        totalPricePY = parseFloat(totalPricePY).toFixed(2)
        exclPricePY = parseFloat(exclPricePY).toFixed(2)
        profitPrice = parseFloat(profitPrice).toFixed(2)
        recommendedPricePY = parseFloat(recommendedPricePY).toFixed(2)
        totalPrice = parseFloat(totalPrice).toFixed(2)
        exclPrice = parseFloat(exclPrice).toFixed(2)
        recommendedPrice = parseFloat(recommendedPrice).toFixed(2)
        window.localStorage.setItem('excl_price', exclPricePY)
        window.localStorage.setItem('total_price', totalPricePY)
        window.localStorage.setItem('recommended_price', recommendedPricePY)

        $('body #total-price').html('<span><strong>€ ' + totalPricePY + '</strong></span><span class="total-price-description">inclusief btw</span>')
        $('body .cvz-mcw-container .total-price .price').html(recommendedPrice + ',-')
        $('body .cvz-mcw-container .sale-price').html(totalPrice + ',-')
        $('body #total-title').html(perYear)
      } else {
        window.localStorage.setItem('payment', 'monthly')

        if ($(offer).length > 0) {
          // exclPrice = (parseFloat(offer.excl_price) + exclPrice)
          // recommendedPrice = (parseFloat(offer.recommended_price) + recommendedPrice)
          // totalPrice = (parseFloat(offer.price) + totalPrice)
        } else {

        }

        profitPrice = recommendedPrice - totalPrice

        if ($(offer).length > 0) {
          profitPrice += (parseFloat(offer.recommended_price)) - (parseFloat(offer.price))
        }

        totalPrice = parseFloat(totalPrice).toFixed(2)
        recommendedPrice = parseFloat(recommendedPrice).toFixed(2)
        exclPrice = parseFloat(exclPrice).toFixed(2)
        profitPrice = parseFloat(profitPrice).toFixed(2)
        window.localStorage.setItem('excl_price', exclPrice)
        window.localStorage.setItem('total_price', totalPrice)
        window.localStorage.setItem('recommended_price', recommendedPrice)



        $('body #total-price').html('<span><strong>€ ' + totalPrice + '</strong></span><span class="total-price-description">inclusief btw</span>')
        $('body .cvz-mcw-container .discount p').html(profitPrice + ',-')
        $('body .cvz-mcw-container .total-price .price').html(recommendedPrice + ',-')
        $('body .cvz-mcw-container .sale-price').html(totalPrice + ',-')
        $('body #total-title').html(perMonth)
      }
    }
  }

  resetProductOverview () {
    document.getElementById('mcw_form').classList.add('col-md-12')
    document.getElementById('cvz-mcw-container').classList.add('mcw_form_steps')

    document.getElementById('mcw_form').classList.remove('col-md-8')
    document.getElementById('mcw_payment').classList.remove('show')
    document.getElementById('cvz-mcw-container').classList.remove('mcw_form_package')
    $('.mcw_offer').removeClass('show-block')
    $('div').remove('.mcw_form_package_wrapper')
    $('.modal-top-right').html('')
  }

  endLoading () {
    //loading
    const self = this

    self.showLoadingScreen('.loading-end', false)
    $('#last_check').attr('disabled', true,)
    $('.tab').css('display', 'none')

    setTimeout(function () {
      $('.cl-1').toggleClass('load-complete')
      $('.cm-1').toggle()
      $('.ct-1').fadeIn(800)
    }, 1000)

    setTimeout(function () {
      $('.cl-2').toggleClass('load-complete')
      $('.cm-2').toggle()
      $('.ct-2').fadeIn(800)
    }, 1500)

    setTimeout(function () {
      $('.cl-3').toggleClass('load-complete')
      $('.cm-3').toggle()
      $('.ct-3').fadeIn(800)
      $('#last_check').attr('disabled', false,)
    }, 2000)

    setTimeout(function () {
      $('.tab').css('display', '')
      self.hideLoadingScreen()
      $('.circle-loader').toggleClass('load-complete')
      $('.checkmark').toggle()

      $('.ct-1').fadeOut()
      $('.ct-2').fadeOut()
      $('.ct-3').fadeOut()
    }, 3000)
  }

  showLoadingScreen (selector, time) {
    const self = this

    if (!selector) {
      console.error('missing selector')

      return
    }

    var callback

    if (typeof time === 'undefined') {
      time = 1000
    }

    if (time === false) {
      callback = function () {}
    } else {
      callback = function () {
        setTimeout(function () {
          self.hideLoadingScreen()
        }, time)
      }
    }

    if (selector == '.loading-start') {
      $('#mcw_steps').css('opacity', '0')
      $('.steps').css('opacity', '0')
    }

    //disable the buttons
    $('#nextBtn').attr('disabled', true,)
    $('#prevBtn').attr('disabled', true,)
    $('.tab').css('opacity', '0')
    $('.tab').css('max-height', '420px')
    $('.tab').css('min-height', '420px')
    $('#mcw_payment').css('opacity', '0')
    $('#mcw_payment').css('display', 'none')
    $('.mcw_form_package_wrapper').css('display', 'none')

    $(selector).fadeIn(callback)
  }

  hideLoadingScreen () {
    //enable loading start
    $('#mcw_steps').css('opacity', '1')
    $('.steps').css('opacity', '1')

    //enable the buttons
    $('#nextBtn').attr('disabled', false)
    $('#prevBtn').attr('disabled', false)
    $('.tab').css('opacity', '1')
    $('.tab').css('max-height', '')
    $('.tab').css('min-height', '')
    $('#mcw_payment').css('opacity', '1')
    $('#mcw_payment').css('display', '')
    $('.mcw_form_package_wrapper').css('display', '')
    $('.mcw_form_answers').css('max-height', '')

    $('.loading-screen').fadeOut()
  }

  productTabs () {
    $('a[name=modal]').click(function (e) {

      //Cancel the link behavior
      e.preventDefault()
      //Get the A tag
      const id = $(this).attr('href')

      //transition effect
      $(id).fadeIn(500).css('display', 'flex!important')
    })

    //if close button is clicked
    $('.modal .close').click(function (e) {

      //Cancel the link behavior
      e.preventDefault()

      $('.modal').fadeOut(500)

    })
  }

}

$(function () {
  new CvZeker()
})

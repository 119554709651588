import './../../scss/_components/_maintenance-planner.scss'
import validate from 'validate.js'

import flatpickr from 'flatpickr'
import { Dutch } from 'flatpickr/dist/l10n/nl'
import _ from 'underscore'
import AddressLookup from './address-lookup'

(function () {
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  var getPostCode = getUrlParameter('planner-postcode');

  const slotPostcodeElement = $('input[name="slotspostcode"]')
  const slotNumberElement = $('input[name="slotsnumber"]')
  const slotnumberAdditionElement = $('input[name="slotsnumberAddition"]')

  const genderElement = $('input:radio[name="gender_option"]')
  const firstNameElement = $('input[name="first_name"]')
  const lastNameElement = $('input[name="last_name"]')
  const emailAddressElement = $('input[name="emailadres"]')
  const phoneNumberElement = $('input[name="phonenumber"]')
  const postcodeElement = $('input[name="postcode"]')
  const numberElement = $('input[name="number"]')
  const numberAdditionElement = $('input[name="numberAddition"]')
  const streetElement = $('input[name="street"]')
  const cityElement = $('input[name="city"]')
  const internalRemarkElement = $('textarea[name="comments"]')
  const dateElement = $('input[name="maintenance_date"]')
  const slotElement = $('input[name="maintenance_slot"]')

  let timeSlots = null

  if (getPostCode !== ''){
    $('input#slotspostcode').val(getPostCode);
  }

  // These are the constraints used to validate the form
  var constraints = {
    customer: {
      presence: true,
    },
    slotspostcode: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    },
    slotsnumber: {
      presence: true,
      format: {
        // We don't allow anything that a-z and 0-9
        pattern: '[a-zA-Z0-9_ ]+',
        // but we don't care if the username is uppercase or lowercase
        flags: 'i',
        message: 'can only contain a-z and 0-9'
      }
    },
  }
  var form = document.querySelector('form#get_available_slots')

  //get available dates
  $('.get-available-dates').click(function (ev) {
    ev.preventDefault()
    handleFormSubmit(form)
  })

  //get the selected slot
  $(".pick-timeslot").on("click", ".slot", function(e) {
    const element = $(e.currentTarget)
    const input = element.find('input')

    $('input#maintenance_slot').val(input.val())
    $('input#maintenance_slot').prop('disabled', true)
    $('#postcode').trigger('change');

    form = document.querySelector('form#planning-customer-information')
    new AddressLookup($(form))
    constraints = {
      maintenance_slot: {
        presence: true,
      },
      maintenance_date: {
        presence: true,
      },
      gender_option: {
        presence: true,
      },
      first_name: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      last_name: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      phonenumber: {
        presence: true,
        length: {
          minimum: 10,
          maximum: 20
        },
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '^[0-9 - +]+$',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',

          message: 'can only contain 0-9'
        }
      },
      emailadres: {
        // Email is required
        presence: true,
        // and must be an email (duh)
        email: true
      },
      postcode: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      number: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      street: {
        presence: true,
      },
      city: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      }
    }

  })

  //send appointment fields
  $('.send-appointment-request').click(function (ev) {
    ev.preventDefault()

    form = document.querySelector('form#planning-customer-information')
    new AddressLookup($(form))
    constraints = {
      maintenance_slot: {
        presence: true,
      },
      maintenance_date: {
        presence: true,
      },
      gender_option: {
        presence: true,
      },
      first_name: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      last_name: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      phonenumber: {
        presence: true,
        length: {
          minimum: 10,
          maximum: 20
        },
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '^[0-9 - +]+$',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',

          message: 'can only contain 0-9'
        }
      },
      emailadres: {
        // Email is required
        presence: true,
        // and must be an email (duh)
        email: true
      },
      postcode: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      number: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      },
      street: {
        presence: true,
      },
      city: {
        presence: true,
        format: {
          // We don't allow anything that a-z and 0-9
          pattern: '[a-zA-Z0-9_ ]+',
          // but we don't care if the username is uppercase or lowercase
          flags: 'i',
          message: 'can only contain a-z and 0-9'
        }
      }
    }

    handleFormSubmit(form)

  })

  // Hook up the inputs to validate on the fly
  var inputs = document.querySelectorAll('input, textarea, select')
  for (var i = 0; i < inputs.length; ++i) {
    inputs.item(i).addEventListener('change', function (ev) {
      var errors = validate(form, constraints) || {}
        showErrorsForInput(this, errors[this.name])
    })
  }

  function handleFormSubmit (form, input) {
    // validate the form aainst the constraints
    var errors = validate(form, constraints)
    //var passwordErrors = validate(form, passwordConstraints)
    // then we update the form to reflect the results
    showErrors(form, errors || {})
    if (!errors) {
      showSuccess()
    }
  }

  // Updates the inputs with the validation errors
  function showErrors (form, errors) {
    // We loop through all the inputs and show the errors for that input
    _.each(form.querySelectorAll('input[name], select[name]'), function (input) {
      // Since the errors can be null if no errors were found we need to handle
      // that
      showErrorsForInput(input, errors && errors[input.name])
    })
  }

  // Shows the errors for a specific input
  function showErrorsForInput (input, errors) {
    // we first mark the group has having errors
    var formGroup = closestParent(input.parentNode, 'mcw_form_validate')

    // First we remove any old messages and resets the classes
    resetFormGroup(formGroup)

    if (errors) {
      // If we have errors
      formGroup.classList.add('has-error')
      _.each(errors, function (error) {
        if ($(formGroup).hasClass('mcw_form_password_row')) {
          $(formGroup).find('.messages').html(error)
        }
      })
    } else {
      // otherwise we simply mark it as success
      formGroup.classList.add('has-success')
    }
  }

  // Recusively finds the closest parent that has the specified class
  function closestParent (child, className) {
    if (!child || child == document) {
      return null
    }
    if (child.classList.contains(className)) {
      return child
    } else {
      return closestParent(child.parentNode, className)
    }
  }

  function resetFormGroup (formGroup) {
    // Remove the success and error classes
    formGroup.classList.remove('has-error')
    formGroup.classList.remove('has-success')
    // and remove any old messages
    _.each(formGroup.querySelectorAll('.help-block.error'), function (el) {
      el.parentNode.removeChild(el)
    })
  }

  // Adds the specified error with the following markup
  // <p class="help-block error">[message]</p>
  function addError (messages, error) {
    var block = document.createElement('p')
    block.classList.add('help-block')
    block.classList.add('error')
    block.innerText = error
    messages.appendChild(block)
  }

  function showSuccess () {
    switch ($(form).attr('id')) {
      case 'get_available_slots':
        getAvailableSlots()
        $('.pick-date .step').slideDown();
        break;
      case 'planning-customer-information':
        sendAppointmentRequest()
        break;
      default:
    }
  }

  function getAvailableSlots () {
    $.ajax({
      method: 'POST',
      url: cvzeker.ajax_url,
      data: {
        action: 'mkoz_available_slots',
        postcode: slotPostcodeElement.val(),
        number: slotNumberElement.val(),
      },
      beforeSend: () => {
      }
    })
      .done(function (list) {
        const datesEnabled = Object.keys(list)
        timeSlots = list

        const calender = flatpickr('#planning_date', {
          altInput: true,
          altFormat: 'd-m-Y',
          dateFormat: 'Y-m-d',
          locale: Dutch,
          enable: datesEnabled,
          minDate: new Date().fp_incr(1),
          maxDate: datesEnabled[datesEnabled-1],
          inline: true,
          onChange: showAvailableDates.bind(this),
        })
      })
      .fail(function (error) {
      })
      .always(() => {
      })
  }

  //get the selected date
  function showAvailableDates (selectedDates, dateStr, instance) {
    $('input#maintenance_date').val(dateStr)
    $('input#maintenance_date').prop('disabled', true);

    $('input#postcode').val(slotPostcodeElement.val());
    $('input#number').val(slotNumberElement.val());
    $('input#numberAddition').val(slotnumberAdditionElement.val());

    //slide next wrapper
    $('.send-information .step').slideDown();
    $('.pick-timeslot .slots').html('');
    showAvailableTimeSlots(dateStr)
  }

  function showAvailableTimeSlots (dateStr) {
    $.each(timeSlots[dateStr], function (i, item) {
      const slot = `<div class="slot"><input type="radio" name="slot" value="${item.description}" id="${item.description}"><label for='${item.description}'>${item.description}</label></div>`
      $('.pick-timeslot .slots').append(slot)
    })
  }

  function sendAppointmentRequest () {
    $.ajax({
      method: 'POST',
      url: cvzeker.ajax_url,
      data: {
        action: 'mkoz_appointment',
        gender: genderElement.val(),
        firstName: firstNameElement.val(),
        lastName: lastNameElement.val(),
        emailAddress: emailAddressElement.val(),
        phoneNumber: phoneNumberElement.val(),
        postcode: postcodeElement.val(),
        number: numberElement.val(),
        numberAddition: numberAdditionElement.val(),
        street: streetElement.val(),
        city: cityElement.val(),
        internalRemark: internalRemarkElement.val(),
        date: dateElement.val(),
        timetable: slotElement.val()
      },
      beforeSend: () => {
      }
    })
      .done(() => {
        $('.maintenanceplanner').html('<h2>Onderhoudsplanner</h2><p>Bedankt voor je bericht! We zullen binnenkort contact met je opnemen.</p>')
      })
      .fail(() => {
      })
      .always(() => {
      })
  }

})()
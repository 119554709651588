import validate from 'validate.js'
import _ from 'underscore'

(function () {


  const emailAddressElement = $('input[name="emailadres"]')
  const passwordElement = $('input[name="password"]')
  const relationElement = $('input[name="relation"]')
  const tokenElement = $('input[name="token"]')

  if ( $('div').hasClass('cv-forgot-password') ) {
    var constraints = {
      emailadres: {
        // Email is required
        presence: true,
        // and must be an email (duh)
        email: true
      },
    }
    var form = document.querySelector('form#forgot_password')
  }

  //get available dates
  $('.send-forget-password-request').click(function (ev) {
    // These are the constraints used to validate the form

    ev.preventDefault()
    handleFormSubmit(form)
  })

  //send appointment fields
  $('.password-reset').click(function (ev) {
    ev.preventDefault()

    form = document.querySelector('form#change_password')
    constraints = {
      password: {
        presence: {
          allowEmpty: false,
          message: 'veld moet ingevuld worden.',
        },

        length: {
          minimum: 8,
          message: 'veld moet minimaal 8 tekens bevatten.'
        }
      }
    }

    handleFormSubmit(form)

  })

  // Hook up the inputs to validate on the fly
  var inputs = document.querySelectorAll('input, textarea, select')

  function handleFormSubmit (form, input) {
    // validate the form aainst the constraints
    var errors = validate(form, constraints)
    //var passwordErrors = validate(form, passwordConstraints)
    // then we update the form to reflect the results
    showErrors(form, errors || {})
    if (!errors) {
      showSuccess()
    }
  }

  // Updates the inputs with the validation errors
  function showErrors (form, errors) {
    // We loop through all the inputs and show the errors for that input
    _.each(form.querySelectorAll('input[name], select[name]'), function (input) {
      // Since the errors can be null if no errors were found we need to handle
      // that
      showErrorsForInput(input, errors && errors[input.name])
    })
  }

  // Shows the errors for a specific input
  function showErrorsForInput (input, errors) {

    // we first mark the group has having errors
    var formGroup = closestParent(input.parentNode, 'mcw_form_validate')

    // First we remove any old messages and resets the classes
    resetFormGroup(formGroup)

    if (errors) {
      // If we have errors
      formGroup.classList.add('has-error')
      _.each(errors, function (error) {
        if ($(formGroup).hasClass('mcw_form_password_row')) {
          $(formGroup).find('.messages').html(error)
        }
      })
    } else {
      // otherwise we simply mark it as success
      formGroup.classList.add('has-success')
    }
  }

  // Recusively finds the closest parent that has the specified class
  function closestParent (child, className) {
    if (!child || child == document) {
      return null
    }
    if (child.classList.contains(className)) {
      return child
    } else {
      return closestParent(child.parentNode, className)
    }
  }

  function resetFormGroup (formGroup) {
    // Remove the success and error classes
    formGroup.classList.remove('has-error')
    formGroup.classList.remove('has-success')
    // and remove any old messages
    _.each(formGroup.querySelectorAll('.help-block.error'), function (el) {
      el.parentNode.removeChild(el)
    })
  }

  // Adds the specified error with the following markup
  // <p class="help-block error">[message]</p>
  function addError (messages, error) {
    var block = document.createElement('p')
    block.classList.add('help-block')
    block.classList.add('error')
    block.innerText = error
    messages.appendChild(block)
  }

  function showSuccess () {
    switch ($(form).attr('id')) {
      case 'forgot_password':
        getForgetPassword()
        break;
      case 'change_password':
        postForgetPassword()
        break;
      default:
    }
  }

  function getForgetPassword () {
    $.ajax({
      method: 'POST',
      url: cvzeker.ajax_url,
      data: {
        action: 'mkoz_get_forgot_password',
        emailAddress: emailAddressElement.val(),
      },
      beforeSend: () => {
        $('.messages').html('')
      }
    })
      .done(() => {
        $('form#forgot_password').html('Mail is verzonden')
      })
      .fail(function (error) {
        const errorMessage = JSON.stringify(error.responseJSON)
        $('.messages').html(errorMessage)
      })
      .always(() => {
      })
  }

  function postForgetPassword () {
    $.ajax({
      method: 'POST',
      url: cvzeker.ajax_url,
      data: {
        action: 'mkoz_post_forgot_password',
        password: passwordElement.val(),
        relation: relationElement.val(),
        token: tokenElement.val()
      },
      beforeSend: () => {
        $('.messages').html('')
      }
    })
      .done(() => {
        $('form#change_password').html('Wachtwoord is aangepast')
      })
      .fail(function (error) {
        const errorMessage = JSON.stringify(error.responseJSON)
        $('.messages').html(errorMessage)
      })
      .always(() => {
      })
  }

})()
'use strict';

$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    $('.scrollup').fadeIn();
  } else {
    $('.scrollup').fadeOut();
  }
});

$('.scrollup').click(function () {
  $('html, body').animate({
    scrollTop: 0
  }, 600);
  return false;
});

$(window).scroll(function () {
  const offset = 0
  const outer = $('.cvz-woocommerce-single').outerHeight() - $('.entry-shopping-cart').outerHeight() + 200
  let sticky = false
  let top = $(this).scrollTop()

  if ($('.cvz-woocommerce-single').offset() !== undefined) {

    if ($('.cvz-woocommerce-single').offset().top < top && outer > top) {
      $('.entry-shopping-cart').addClass('sticky');
      $('.entry-shopping-cart').removeClass('sticky-bottom');
      sticky = true;
    } else if ($('.cvz-woocommerce-single').offset().top < top && outer < top) {
      $('.entry-shopping-cart').removeClass('sticky');
      $('.entry-shopping-cart').addClass('sticky-bottom');
    }
    else {
      $('.entry-shopping-cart').removeClass('sticky');
      $('.entry-shopping-cart').removeClass('sticky-bottom');
    }
  }
});
'use strict';

function productSEO() {

}

productSEO.prototype.initialize = function() {
  if ( $('article').hasClass('single-product-details') ) {

    const name = $('.single-product-details').attr('data-product-name');
    const sku = $('.single-product-details').attr('data-product-sku');
    const description = $('.single-product-details').attr('data-product-description');
    const image = $('.single-product-details').attr('data-product-image');
    const brand = $('.single-product-details').attr('data-product-brand');
    const url = $('.single-product-details').attr('data-product-url');
    const price = $('.single-product-details').attr('data-product-price');
    const salePrice = $('.single-product-details').attr('data-product-sale-price');

    $('body').append('<script class="productSEO" type="application/ld+json"></script>')

    $(".productSEO").text(function() {
      return JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": name,
        "image": [
          "https://www.mkoz-2019.nl" + image,
        ],
        "description": description,
        "sku": sku,
        "brand": {
          "@type": "product",
          "name": brand,
        },
        "offers": {
          "@type": "Offer",
          "url": url,
          "priceCurrency": "EU",
          "price": salePrice,
          "availability": "https://schema.org/InStock",
          "seller": {
            "@type": "Organization",
            "url": "https://www.mkoz-2019.nl"
          }
        }
      });
    });
  }
};




module.exports = new productSEO();

$('body').on('click', '.collapse-head', (e) => {
  $header = $(e.currentTarget);
  //getting the next element
  $content = $header.next();
  //open up the content needed - toggle the slide- if visible, slide up, if not slidedown.
  $content.stop().slideToggle(700, function () {
    //execute this after slideToggle is done
    //change text of header based on visibility of content div
    $header.text(function () {
      //change text based on condition
    });
  });

});

$(".collapse-wrapper .content").click(function () {
  $header = $(this);

  // window.location.href = 'https://www.mijnkantooropzak.nl/vergelijk/';

  $header.stop().slideUp(1000, function () {
    //execute this after slideToggle is done
    //change text of header based on visibility of content div
    $header.text(function () {
      //change text based on condition
    });
  });
});

$(".thermo-wrapper .content").click(function () {
  $header = $(".service-wrapper .content")

  $header.slideDown(1000, function () {
    //execute this after slideToggle is done
    //change text of header based on visibility of content div
    $header.text(function () {
    });
  });
});

$(".service-wrapper .content").click(function () {
  $('.cvz-complete-message').html('<strong>Je bent klaar om te bestellen! </strong><p>Klik op de winkelwagen button om je bestelling af te ronden.</p>')
});
'use strict';

function productPriceUpdate() {

}

productPriceUpdate.prototype.initialize = function() {
  this.intro();
  this.update();
  this.assembleCall();
};

productPriceUpdate.prototype.intro = function() {

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  if (getUrlParameter('mode') === 'order') {
    $('.entry-shopping-cart-intro').addClass('hide-block')
    $('.entry-price-footer .cvz-btn').addClass('hide-block')
    $('.entry-shopping-cart-order').removeClass('hide-block')
    $('.collapse-wrapper').removeClass('hide-block')
    $('.product-quotation-mode').html('')
    $('.product-order-mode').removeClass('hide-block')
    $('.tabs-installation').addClass('hide-block')
    $('.entry-price-footer .share-icons').addClass('share-icons-order')
    $('.assemble-wrapper').removeClass('hide-block')
    $('.assemble-wrapper .collapse-head').click()
    $('.product-order-mode .collapse-head').click()
  }
};

productPriceUpdate.prototype.update = function() {

  //onderhoud ontkoppelen
  $('.service-wrapper').hide();
  $('.entry-service .cart').removeClass('selected');

  //thermostaat price
  $(".entry-thermo").on("click", ".cart", function(e) {
    const element = $(e.currentTarget)
    const price = element.attr('data-price')
    const title = element.attr('data-title')

    const productText = `<td id="title">${ title }</td><td id="price">€${ price }</td>`

    $('.thermo').html(productText)
    $('.entry-thermo .cart').removeClass('selected');

    element.addClass('selected')
    element.trigger('change');
  });

  //servicepakket price
  $(".entry-service").on("click", ".cart", function(e) {

    const element = $(e.currentTarget)
    let price = element.attr('data-price')
    const title = element.attr('data-title')
    const discountMode = element.attr('data-discount-mode')

    let productText = `<td id="title">${ title }</td><td id="price">€${ price }</td>`

    if (discountMode === "1") {
      $('.discount-mode').html(productText)
      $('.service').html('')
    } else {
      $('.discount-mode').html('')
      $('.service').html(productText)
    }
    $('.entry-service .cart').removeClass('selected');

    element.addClass('selected')
    element.trigger('change');
  });

  //installatiepakket price
  $(".entry-assemble").on("click", ".cart", function(e) {
    const element = $(e.currentTarget)
    const price = element.attr('data-price')
    const title = element.attr('data-title')
    let noAssemble = false

    const productText = `<td id="title">${ title }</td><td id="price">€${ price }</td>`

    if (title === 'Geen montage' || title === 'Installatiepakket' ) {
      noAssemble = true;
    }

    if (noAssemble === false) {
      $('.service-wrapper').show();
      $('.thermo-wrapper .collapse-head').next().slideDown(800)
    } else {
      $('.service-wrapper').hide();
      $('.entry-service .product-0 .cart').trigger('click');
      $('.entry-service .product-0 .cart').click();
      $('.thermo-wrapper .collapse-head').next().slideDown(800)
    }

    $('.montage').html(productText)
    $('.entry-assemble .cart').removeClass('selected');

    element.addClass('selected')
    element.trigger('change');
  });

  //check of assemble overschreven mag worden
  function overwriteAssemble () {
    $('.entry-assemble .selected').each(function () {
      if($(this).hasClass('overwrite')) {
        $(this).trigger('click');
      }
    })
  }

  //check of service overschreven mag worden
  function overwriteService () {
    $('.entry-service .selected').each(function () {
      if($(this).hasClass('overwrite')) {
        $(this).trigger('click');
      }
    })
  }

  //Optellen van alle pakketten voor een totaal bedrag
  $(".product-upgrade").on("change", function(e) {
    let price = 0.0
    let regularPrice = 0.0
    let discount = 0.0

    let priceVal = parseFloat($('.total-price').attr('data-total-price'))
    let regularPriceVal = parseFloat($('.total-price').attr('data-regular-price'))

    let productID = $('.cart-total').attr('id')
    let noMaintenance = false

    //berekenen van totaal price
    $('.selected').each(function () {

      if ($(this).attr('id') != null){
        productID = productID + ', ' + $(this).attr('id')
      }

      if ($(this).attr('data-type') != 'Serviceabonnement') {
        price = price + parseFloat($(this).attr('data-price'))
        regularPrice = regularPrice + parseFloat($(this).attr('data-original-price'))
      }

      if ($(this).attr('data-title') === 'Geen onderhoud') {
        noMaintenance = true;
      }
    })

    regularPrice = regularPrice + regularPriceVal
    price = price + priceVal
    discount = regularPrice - price


    const allProducts = $("<button>").val(productID)

    if (noMaintenance === false) {
      const discountText = `<td id="title">Onderhoudsabonnement</td><td id="price">- € ${ discount.toFixed(2) }</td>`
      $('.sale-price').html(price.toFixed(0) + ',-')
      $('.discount-row').html(discountText)

      $('.discount p').html(discount.toFixed(2) + ',-')
      $('.shopping-cart-body .total #price').html('€' + price.toFixed(2))
    } else {
      $('.discount-row').html('')
      $('.sale-price').html(price.toFixed(0) + ',-')
      $('.discount p').html(discount.toFixed(2) + ',-')
      $('.shopping-cart-body .total #price').html('€' + price.toFixed(2))
    }


    $('.on-sale .price').html(regularPrice.toFixed(0) + ',-')
    $('.cart-total button').attr('value', productID)

  }).trigger('change')

  overwriteService();
  overwriteAssemble();


  //betaal keuze opslaan
  $('body').on('click', '.order-call', (e) => {
    window.localStorage.setItem('order-type', 'product')
    window.localStorage.setItem('check-out-type', 'order')
  })

  $('body').on('click', '.quotation-call', (e) => {
    window.localStorage.setItem('order-type', 'product')
    window.localStorage.setItem('check-out-type', 'quotation')
  })
};

productPriceUpdate.prototype.assembleCall = function () {
  const text = `
    <h5>Laat ons je helpen met de montage van deze CV-Ketel</h5>
 
    <p>
    Voeg eenvoudig montage hulp pakket toe aan je winkelwagen. Dit is direct bij te bestellen wanneer de huidige situatie voldoet aan onze installatie voorwaarden.
    </p>
     
    <ul>
        <li>De bestaande CV-Ketel is er een van het type combiketel</li>
        <li>Binnen 1 meter van de bestaande CV-Ketel bevindt zich de rioolafvoer</li>
        <li>De vervangende CV-Ketel wordt aangesloten op de bestaande HR dak of muurdoorvoer.</li>
    </ul>
    <p>
    CV Zeker bied je de <strong>laagste prijsgarantie</strong> en een standaard <strong>garantie van 5 jaar op de montage en onderdelen</strong>. Dit in combinatie met een onderhoudscontract bij CV Zeker.
    </p> 
     
    <p>Het Montage hulp pakket is altijd inclusief het vervangen van de volgende onderdelen: Expansievat, koppelingen en klein materiaal. Wanneer nodig zullen wij de inlaatcombinatie, gaskraan en leidingen mee vervangen.</p> 
     
    <p>Is de bestaande rookgasafvoer onveilig, dan adviseren wij je een nieuwe rookgasafvoer te plaatsen. De meerprijs bij een standaard rookgasafvoer vervanging is € 99,- incl. btw. Mocht de situatie zo zijn dat standaard vervanging niet plaats kan vinden. Dan wordt de meerprijs in overleg bepaald bij plaatsing of indien gewenst voor opdracht.</p>
     
    <p>Woon je in een appartement? Of is de dak doorvoer niet pal boven de CV-Ketel aanwezig? Neem voor deze of andere vragen <a href="/contact">contact met ons op</a> dan maken wij vrijblijvend een offerte op maat.</p>
  `


  $('body').on('click', '.assemble-call', (e) => {
    $('.assemble-wrapper').addClass('show')
    $('.entry-product-tab-inner').addClass('hide-block')
    $('.product-logo').addClass('hide-block')
    $('.product-stock-status').addClass('hide-block')
    $('.entry-share').addClass('hide-block')
    // $('.entry-summary').html(`<div class="entry-summary-inner">${text}</div>`)
    $('.assemble-call').html('<a style="color: white; display: block" href="javascript:window.location.href=window.location.href"><i class="fa fa-refresh" aria-hidden="true"></i> Reset filter</a>')
  })

  $('.entry-service .product-0 .cart').trigger('click');
  $('.entry-service .product-0 .cart').click();
  $('.cvz-complete-message').html('')
}

module.exports = new productPriceUpdate();
